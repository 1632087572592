import React from 'react';
import './Pagination.css';

const Pagination = ({ currentPage = 1, availablePages = [], onPageChange }) => {
  const totalPages = availablePages.length;
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === totalPages;

  const getVisiblePages = () => {
    const pages = [];
    for (let i = 0; i < 3; i++) {
      const pageNumber = currentPage + i;
      if (pageNumber <= totalPages) {
        pages.push(pageNumber);
      }
    }
    return pages;
  };

  const visiblePages = getVisiblePages();

  return (
    <div className="pagination-container">
      {!isFirstPage && (
        <button
          className="pagination-button"
          onClick={() => onPageChange(currentPage - 1)}
          aria-label="Previous page"
        >
          ←
        </button>
      )}

      {visiblePages.map((page) => (
        <button
          key={page}
          className={`pagination-button ${page === currentPage ? 'active' : ''}`}
          onClick={() => onPageChange(page)}
        >
          {page}
        </button>
      ))}

      {!isLastPage && visiblePages[visiblePages.length - 1] < totalPages && (
        <button
          className="pagination-button"
          onClick={() => onPageChange(currentPage + 1)}
          aria-label="Next page"
        >
          →
        </button>
      )}
    </div>
  );
};

export default Pagination;
