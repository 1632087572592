import React, { useState, useEffect } from "react";
import { Container, Form, Button, Row, Col, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./signupthree.css";

const SignupthreeForm = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000'; // Fallback URL for development
  
  const [formData, setFormData] = useState({
    organisation: "",
    industry: "",
    size: "",
    website: "",
    userId: userId,
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const industries = [
    "Technology and IT",
    "Healthcare and Pharmaceuticals",
    "Finance and Banking",
    "E-commerce and Retail",
    "Energy and Utilities",
    "Manufacturing and Automotive",
    "Telecommunications",
    "Construction and Real Estate",
    "Entertainment and Media",
    "Education and Training",
    "Food and Beverage",
    "Agriculture and Agribusiness",
    "Aerospace and Defense",
    "Travel and Tourism",
    "Fashion and Apparel",
    "Mining and Metals",
    "Chemicals and Materials",
    "Logistics and Supply Chain",
    "Consulting and Professional Services",
    "Environmental and Sustainability"
  ].sort();

  useEffect(() => {
    if (!userId) {
      navigate("/signup");
    }
  }, [userId, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const clearDataAndRedirect = () => {
    // Clear cookies
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    // Clear localStorage
    localStorage.clear();

    // Redirect to login page
    navigate("/login");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${apiUrl}/api/signupthree`, formData);
      console.log("API Response:", response);

      if (response.status >= 200 && response.status < 300) {
        setSuccess(true);
        setError(null);
        console.log("Signup successful:", response.data);
        clearDataAndRedirect();
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (err) {
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setError(`Server error: ${err.response.data.message || 'Unknown error'}`);
      } else if (err.request) {
        // The request was made but no response was received
        setError("No response from server. Please check your internet connection.");
      } else {
        // Something happened in setting up the request that triggered an Error
        setError("An error occurred. Please try again later.");
      }
      console.error("Error during signup:", err);
    }
  };

  const handleSkip = () => {
    clearDataAndRedirect();
  };

  return (
    <Container className="signup-three-container">
      <Form
        onSubmit={handleSubmit}
        className="signup-three-form"
      >
        <div className="signup-three-skip-container">
          <span 
            className="signup-three-skip-link"
            onClick={handleSkip}
          >
            Skip
          </span>
        </div>
        <h2 className="signup-three-heading">Almost There</h2>
        <p className="signup-three-subheading">
          Please enter the details
        </p>

        <Form.Group controlId="formorganisation" className="signup-three-input">
          <Form.Control
            type="text"
            name="organisation"
            placeholder="Enter Organisation Name"
            value={formData.organisation}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formindustry" className="signup-three-input mb-3">
        <Form.Select
            name="industry"
            value={formData.industry}
            onChange={handleChange}
            required
            className="custom-select"
            >
            <option value="" disabled>Select your industry</option>
            {industries.map((industry, index) => (
              <option key={index} value={industry}>{industry}</option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="formsize" className="signup-three-input mb-3">
          <Form.Select
            name="size"
            value={formData.size}
            onChange={handleChange}
            required
            className="custom-select"
          >
            <option value=""disabled>Choose Employee Size</option>
            <option value="0-10">0-10</option>
            <option value="11-20">11-20</option>
            <option value="21-50">21-50</option>
            <option value="51-100">51-100</option>
            <option value="101-200">101-200</option>
            <option value="201-500">201-500</option>
            <option value="501-1000">501-1000</option>
            <option value="1001-5000">1001-5000</option>
            <option value="5001+">5001+</option>
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="formwebsite" className="signup-three-input">
          <Form.Control
            type="url"
            name="website"
            placeholder="Website"
            value={formData.website}
            onChange={handleChange}
            required
          />
        </Form.Group>

        {error && <Alert variant="danger" className="mb-3">{error}</Alert>}
        {success && <Alert variant="success" className="mb-3">Signup successful!</Alert>}

        <Row className="w-100">
          <Col xs={12} className="mb-3">
            <Button variant="primary" type="submit" className="signup-three-submit-btn">
              Sign Up
            </Button>
          </Col>
        </Row>
        <p className="signup-three-login-link">
          Already have an account? <Link to="/login">Login</Link>
        </p>
      </Form>
    </Container>
  );
};

export default SignupthreeForm;
