import React from 'react';
import { Container, Button, Card, Row, Col } from 'react-bootstrap';
import { FaUserShield, FaLock, FaDatabase, FaUserSecret } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import GuardrailsAnimation from './GuardrailsAnimation';
import './SafeAndSecure.css';

const SafeAndSecure = () => {
  const navigate = useNavigate();
  const LeftSideContent = () => (
    <div className="left-side-content">
      <div className="safe-and-secure-item">
        <FaUserShield className="safe-and-secure-icon" />
        <div>
          <h3>Supervision</h3>
          <p>Guardrails ensure your agent stays on-topic, and real-time monitoring tracks live interactions.</p>
        </div>
      </div>
      <div className="safe-and-secure-item">
        <FaLock className="safe-and-secure-icon" />
        <div>
          <h3>Secure integration</h3>
          <p>Our system integrates securely with your existing infrastructure, ensuring data protection at all times.</p>
        </div>
      </div>
      <div className="safe-and-secure-item">
        <FaDatabase className="safe-and-secure-icon" />
        <div>
          <h3>Data governance</h3>
          <p>Robust data governance policies ensure proper handling and storage of all information.</p>
        </div>
      </div>
      <div className="safe-and-secure-item">
        <FaUserSecret className="safe-and-secure-icon" />
        <div>
          <h3>Privacy</h3>
          <p>We prioritize user privacy, implementing strict measures to protect personal information.</p>
        </div>
      </div>
    </div>
  );

  return (
    <section className="safe-and-secure-section">
      <div className="safe-and-secure-header">
        <h2 className="safe-and-secure-heading">Your Security is Our Priority</h2>
        <p className="safe-and-secure-subheading">
          Experience peace of mind with our robust security measures and unwavering commitment to protecting your data.
        </p>
      </div>
      <Container>
        <div className="safe-and-secure-content">
          <Button 
            variant="primary" 
            className="cta-button"
            onClick={() => navigate('/data-security-policy')}
          >
            Learn More About Our Security
          </Button>
          
          <Row>
            <Col md={6}>
              <LeftSideContent />
            </Col>
            <Col md={6}>
              <Card className="conversation-card">
                <Card.Body>
                  <GuardrailsAnimation />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default SafeAndSecure;
