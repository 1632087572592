import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import axios from "axios";
import './BlogDetailPage.css';
import BlogListPage from "./BlogListPage";
import BlogDetailPage from "./BlogDetailPage";
import ComingSoon from "../../components/ComingSoon";

const BlogRoutes = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastKey, setLastKey] = useState(null);
  const [availablePages, setAvailablePages] = useState([1]);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true);
        const lastKeyEncoded = encodeURIComponent(JSON.stringify(lastKey));
        const response = await axios.get(`${apiUrl}/api/blogs?page=${currentPage - 1}&lastEvaluatedKey=${lastKeyEncoded}`);

        const data = response.data;
        if (data.data && data.data.length > 0) {
          setBlogs(prevBlogs => {
            const newBlogs = [...prevBlogs, ...data.data];
            return newBlogs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          });
          setLastKey(data.lastEvaluatedKey);
          
          // Update available pages
          if (!availablePages.includes(currentPage)) {
            setAvailablePages(prevPages => [...prevPages, currentPage].sort((a, b) => a - b));
          }
        }
        setLoading(false);
      } catch (error) {
        setError("Failed to fetch blogs");
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [currentPage, apiUrl]);
  
  const handlePageChange = (page) => {
    if (availablePages.includes(page) || page === availablePages[availablePages.length - 1] + 1) {
      setCurrentPage(page);
    }
  };

  if (loading && blogs.length === 0) {
    return <ComingSoon />;
  }

  if (error || blogs.length === 0) {
    return <ComingSoon />;
  }

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-grow">
        <Routes>
          <Route
            path="/"
            element={
              <BlogListPage
                blogs={blogs}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                availablePages={availablePages}
              />
            }
          />
          <Route path="/blog/:postId" element={<BlogDetailPage />} />
        </Routes>
      </div>
    </div>
  );
};

export default BlogRoutes;
