import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import './about.css';
import { Link } from 'react-router-dom';
import { visionImage, journeyImage } from '../../assets';

const About = () => {
  return (
    <div className="about-page">
      <div className="hero-section">
        <h1>About Us</h1>
      </div>

      <Container>
        <section className="who-we-are">
          <h2>Who are we, and what do we do?</h2>
          <p>
            Verbalyze is a pioneering AI company dedicated to revolutionizing customer interactions through advanced conversational AI technology. We specialize in developing cutting-edge solutions that empower businesses to enhance their customer service, streamline operations, and create more engaging user experiences.
          </p>
        </section>

        <section className="our-vision">
          <Row>
            <Col md={6}>
              <h2>Our Vision</h2>
              <p>
                At Verbalyze, we envision a future where AI-driven conversations seamlessly bridge the gap between businesses and their customers. Our mission is to create intelligent, empathetic, and efficient AI systems that understand and respond to human needs, transforming the way organizations interact with their audiences.
              </p>
              <p>
                We strive to be at the forefront of AI innovation, continuously pushing the boundaries of what's possible in natural language processing and machine learning. Our goal is to empower businesses across industries with tools that not only automate processes but also enhance the quality and personalization of customer interactions.
              </p>
            </Col>
            <Col md={6}>
              <Image src={visionImage} alt="Our Vision" fluid className="vision-image" />
            </Col>
          </Row>
        </section>

        <section className="our-journey">
          <Row>
            <Col md={6}>
              <Image src={journeyImage} alt="Our Journey" fluid className="journey-image" />
            </Col>
            <Col md={6}>
              <h2>Our Journey</h2>
              <p>
                Founded by innovators passionate about AI and its potential to transform businesses, Verbalyze has grown from a startup with a vision to a leading provider of conversational AI solutions. Our journey is marked by continuous learning, innovation, and a commitment to excellence in AI technology.
              </p>
              <p>
                We've partnered with businesses across various sectors, helping them leverage the power of AI to improve customer satisfaction, increase efficiency, and drive growth. Our success stories span industries from telecommunications to e-commerce, showcasing the versatility and effectiveness of our AI solutions.
              </p>
            </Col>
          </Row>
        </section>

        <section className="learn-more text-center">
          <h2>Want to learn more?</h2>
          <p>Get in touch with us to find out how we can help you transform your customer interactions with AI.</p>
          <div className="button-group">
            <Button variant="outline-primary" as={Link} to="/products" size="lg">OUR PRODUCTS</Button>
            <Button variant="primary" as={Link} to="/contact-us" size="lg">GET IN TOUCH</Button>
            <Button variant="outline-primary" as={Link} to="/blog" size="lg">READ BLOGS</Button>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default About;
