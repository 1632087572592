
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

const phrases = [
  "available 24/7",
  "understands multiple languages",
  "handles customer queries",
  "scales with your business",
];

const floatingAnimation = {
  initial: { y: 0 },
  animate: {
    y: [0, 20, 0],
    transition: {
      duration: 6,
      repeat: Infinity,
      ease: "easeInOut",
    },
  },
};

const rotatingAnimation = {
  initial: { rotate: 0 },
  animate: {
    rotate: 360,
    transition: {
      duration: 20,
      repeat: Infinity,
      ease: "linear",
    },
  },
};

const Banner = () => {
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleGetStarted = () => {
    navigate('/login');
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center overflow-hidden bg-gradient-to-br from-blue-50 to-blue-100">
      <motion.div
        className="absolute top-10 left-10 w-64 h-64 bg-gradient-to-r from-blue-100 to-teal-200 rounded-full opacity-40 blur-3xl"
        {...floatingAnimation}
      />
      <motion.div
        className="absolute bottom-20 right-20 w-96 h-96 bg-gradient-to-r from-cyan-100 to-blue-300 rounded-full opacity-30 blur-2xl"
        {...rotatingAnimation}
      />
      <motion.div
        className="absolute top-1/3 left-1/4 w-48 h-48 bg-gradient-to-tl from-blue-300 to-cyan-200 rounded-full opacity-50 blur-2xl"
        {...floatingAnimation}
      />

      <div className="relative z-10 text-center px-4">
        <motion.h1
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.2 }}
          className="text-4xl md:text-6xl font-bold pb-10 text-shamb-6 bg-clip-text text-transparent bg-gradient-to-r from-gray-600 via-gray-700 to-gray-800"
        >
          Never Miss a Customer Call
        </motion.h1>
        <motion.h2
          initial={{ opacity: 0, y: -30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.4 }}
          className="text-2xl md:text-4xl font-semibold mb-8 text-gray-600"
        >
          with <span className="text-[rgb(11,88,181)]">AI-Talker</span>
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.6 }}
          className="text-xl md:text-2xl mb-4 text-gray-600"
        >
          Conversational AI Agent that's
        </motion.p>
        <div className="h-20 mb-8">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentPhraseIndex}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
              className="text-xl md:text-2xl font-medium text-sky-600"
            >
              {phrases[currentPhraseIndex]}
            </motion.div>
          </AnimatePresence>
        </div>
        <button
          className=" bg-[rgb(11,88,181)] text-2xl text-white font-bold py-4 px-8 rounded-full uppercase tracking-wider transition-all duration-500 hover:shadow-[0_0_5px_rgb(0,140,255),0_0_25px_rgb(0,140,255),0_0_50px_rgb(0,140,255),0_0_100px_rgb(0,140,255)]"
          onClick={handleGetStarted}
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default Banner;
