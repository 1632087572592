import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  const ModernLoader = () => {
    const bars = 10;
    const colors = [
      'from-sky-300 to-sky-100',
      'from-sky-400 to-sky-200',
      'from-blue-300 to-sky-200',
      'from-blue-400 to-blue-200',
      'from-blue-500 to-blue-300',
      'from-blue-300 to-blue-600',
    ];
  
    return (
      <div className="relative w-full max-w-md mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-sky-300/10 blur-3xl" />
        <div className="relative flex justify-center items-center min-h-[240px] p-8 rounded-xl bg-white/5 backdrop-blur-sm">
          <div className="flex items-end justify-center space-x-2">
            {[...Array(bars)].map((_, i) => (
              <motion.div
                key={i}
                className={`w-3 rounded-full bg-gradient-to-t ${colors[i % colors.length]}`}
                animate={{
                  height: [
                    "40px",
                    "120px",
                    "40px"
                  ],
                  opacity: [0.6, 1, 0.6]
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "easeInOut",
                  delay: i * 0.15
                }}
                initial={{
                  height: "40px"
                }}
              >
                <motion.div
                  className="w-full h-full rounded-full bg-gradient-to-b from-white/30 to-transparent"
                  animate={{
                    opacity: [0, 0.6, 0]
                  }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    ease: "easeInOut",
                    delay: i * 0.15
                  }}
                />
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full min-h-screen flex flex-col items-center bg-gradient-to-br from-gray-50 to-blue-50">
      <div className="w-full max-w-7xl px-4 pt-12 pb-16">
        <h1 className="text-5xl font-bold mb-4 mt-8 text-center text-gray-800">
          Contact Us
        </h1>
        <p className="text-lg mb-6 text-center text-gray-600">
          Schedule a meeting with us using the calendar below:
        </p>
        <div className="bg-white rounded-lg shadow-lg overflow-hidden relative min-h-[700px]">
          <AnimatePresence>
            {isLoading && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute inset-0 flex items-center justify-center bg-white z-10"
              >
                <ModernLoader />
              </motion.div>
            )}
          </AnimatePresence>
          <iframe 
            src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2y-VtvsmvfxsJh8iEMqsML7jplPFVdFH3ZYecJszGV_0p1KWQuar18QFnMJqsVuSEuR_09KuDy?gv=true" 
            className="w-full h-[700px] border-0"
            title="Google Calendar Appointment Scheduling"
            onLoad={handleIframeLoad}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
