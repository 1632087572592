import React from 'react';
import { motion } from 'framer-motion';

const ComingSoon = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-100 to-blue-200">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-center text-white p-12 rounded-2xl shadow-2xl bg-white bg-opacity-10 backdrop-filter backdrop-blur-md border  border-opacity-20"
      >
        <motion.h1
          initial={{ scale: 0.5 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.2, type: 'spring', stiffness: 120 }}
          className="text-3xl font-bold mb-6 text-black bg-clip-text "
        >
          Coming Soon
        </motion.h1>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
          className="text-lg mb-8 text-gray-700"
        >
          Our blog is under construction. We're crafting something extraordinary for you!
        </motion.p>
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.8, type: 'spring', stiffness: 120 }}
          className="mt-10"
        >
          <div className="w-20 h-20 border-t-4 border-b-4 border-blue-300 border-solid rounded-full animate-spin mx-auto"></div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default ComingSoon;
