import React from 'react';
import { useNavigate } from 'react-router-dom';

const NoPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-r from-blue-300 via-blue-200 to-blue-300">
      <div className="text-center bg-blue-300 bg-opacity-80 px-48 py-20 rounded-2xl shadow-lg">
        <h1 className="text-5xl mb-3 font-bold text-gray-900">404</h1>
        <p className="text-2xl text-gray-800 mb-4">Oops! Not found</p>
        <button
          onClick={() => navigate('/')}
          className="bg-blue-700 hover:bg-blue-900 text-white text-xl font-bold py-4 px-12 rounded-full transition duration-300 ease-in-out"
        >
          Go Home
        </button>
      </div>
    </div>
  );
};

export default NoPage;
