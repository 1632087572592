import React, { useState } from "react";
import { Container, Form, Button, Row, Col, Alert } from "react-bootstrap";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import CountryCodeInput from "../../countryInput";
import "./Signup.css";

const SignupForm = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000'; // Fallback URL for development
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    countryCode: "+91" // Default country code
  });

  const [errors, setErrors] = useState({});
  const [signupError, setSignupError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    setSignupError(""); // Clear signup error when user types
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear specific field error
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) {
      newErrors.firstName = "First name is required";
    } else if (formData.firstName.length <= 2) {
      newErrors.firstName = `Please type ${3 - formData.firstName.length} more character${3 - formData.firstName.length === 1 ? '' : 's'}`;
    }
    if (!formData.lastName) {
      newErrors.lastName = "Last name is required";
    } else if (formData.lastName.length <= 2) {
      newErrors.lastName = `Please type ${3 - formData.lastName.length} more character${3 - formData.lastName.length === 1 ? '' : 's'}`;
    }
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.phone) newErrors.phone = "Phone number is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    try {
      const fullPhoneNumber = `${formData.countryCode}${formData.phone}`;
      const payload = { ...formData, phone: fullPhoneNumber };
      const response = await axios.post(`${apiUrl}/api/signup`, payload);
  
      // Assuming the API response contains "userId" and "message"
      const { userId } = response.data;
      if (userId) {
        localStorage.setItem("userId", userId); // Store userId in local storage
        navigate("/signuptwo");
      }
    } catch (error) {
      console.error("Error submitting form data", error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 409) {
          setSignupError("User already exists. Please log in or use a different email.");
        } else {
          setSignupError(`Server error: ${error.response.data.message || 'Unknown error'}`);
        }
      } else if (error.request) {
        // The request was made but no response was received
        setSignupError("No response from server. Please check your internet connection.");
      } else {
        // Something happened in setting up the request that triggered an Error
        setSignupError("An error occurred. Please try again later.");
      }
    }
  };
  

  return (
    <Container className="signup-container">
      <Form
        method="post"
        onSubmit={handleSubmit}
        className="login-page"
      >
        <h2 className="login-heading">Create Account</h2>
        <p className="detail">
          Please Enter Your Details
        </p>
        {signupError && <Alert variant="danger" className="w-100 mb-3">{signupError}</Alert>}
        <div className="name-fields">
          <Form.Group controlId="firstName" className="form-group">
            <Form.Control
              type="text"
              placeholder="Enter First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              isInvalid={!!errors.firstName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="lastName" className="form-group">
            <Form.Control
              type="text"
              placeholder="Enter Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              isInvalid={!!errors.lastName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.lastName}
            </Form.Control.Feedback>
          </Form.Group>
        </div>

        <Form.Group controlId="email" className="form-group">
          <Form.Control
            type="email"
            placeholder="Enter Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            isInvalid={!!errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Country Code Input Component */}
        <CountryCodeInput
          formData={formData}
          handleChange={handleChange}
          errors={errors}
        />

        <Button variant="primary" type="submit" className="submitbtn">
          Get OTP
        </Button>
        <p className="bottom-tag">
          Already have an account?{" "}
          <Link className="tag-name" to="/login" style={{ textDecoration: "none" }}>
            <b>Log In</b>
          </Link>
        </p>
      </Form>
    </Container>
  );
};

export default SignupForm;
