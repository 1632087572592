import React, { useState, useEffect, useCallback } from 'react';
import { Container, Button, Card, Row, Col } from 'react-bootstrap';
import { FaRobot, FaUser, FaCheckCircle, FaSmile, FaClock, FaChartLine } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './CustomerExperience.css';

const conversation = [
  { type: 'ai', message: "Welcome! How can I assist you today?" },
  { type: 'user', message: "Can you reschedule my package delivery from tomorrow to end of the week?" },
  { type: 'ai', message: "Sure, it's done. Your package delivery has been rescheduled to the end of the week." },
];

const CustomerExperience = () => {
  const navigate = useNavigate();
  const [visibleMessages, setVisibleMessages] = useState([]);
  const [showEmptyCard, setShowEmptyCard] = useState(false);
  const [fadeOutMessages, setFadeOutMessages] = useState(false);

  const animationCycle = useCallback(() => {
    setVisibleMessages([]);
    setShowEmptyCard(false);
    setFadeOutMessages(false);

    const animationDelays = [200, 2000, 4000];
    
    animationDelays.forEach((delay, index) => {
      setTimeout(() => {
        setVisibleMessages(prev => [...prev, conversation[index]]);
      }, delay);
    });

    // Schedule fade-out and green tick display
    setTimeout(() => {
      setFadeOutMessages(true);
      setTimeout(() => {
        setShowEmptyCard(true);
        setTimeout(() => {
          setShowEmptyCard(false);
          setVisibleMessages([]); // Clear messages before restarting
        }, 3000); // Show empty card for 3 seconds
      }, 1000); // Wait for fade-out animation
    }, 8000); // Start fade-out 4 seconds after the last message appears
  }, []); // Remove 'conversation' from the dependency array

  useEffect(() => {
    animationCycle();

    // Set up an interval to repeat the animation cycle
    const intervalId = setInterval(animationCycle, 14000); // 14 seconds for the entire cycle

    return () => {
      clearInterval(intervalId); // Clean up the interval on component unmount
    };
  }, [animationCycle]);

  const EmptyCardWithTick = () => (
    <div className="empty-card">
      <FaCheckCircle className="green-tick" />
      <p>Customer Support in Realtime</p>
    </div>
  );

  const RightSideContent = () => (
    <div className="right-side-content">
      <div className="customer-experience-item">
        <FaSmile className="customer-experience-icon" />
        <div>
          <h3>Engage and delight customers</h3>
          <p>Deploy an AI agent that is always available, empathetic, and aligned to your brand tone and voice.</p>
        </div>
      </div>
      <div className="customer-experience-item">
        <FaClock className="customer-experience-icon" />
        <div>
          <h3>Support your customers in real-time</h3>
          <p>Help customers with even their most complex issues, whether making an exchange or updating a subscription.</p>
        </div>
      </div>
      <div className="customer-experience-item">
        <FaChartLine className="customer-experience-icon" />
        <div>
          <h3>Adapt and get better, faster</h3>
          <p>Respond swiftly to changes in your business, and harness analytics and reporting to continuously improve the customer experience.</p>
        </div>
      </div>
    </div>
  );

  return (
    <section className="customer-experience-section">
      <div className="customer-experience-header">
        <h2 className="customer-experience-heading">AI-powered customer experience</h2>
        <p className="customer-experience-subheading">
          Engage and delight customers with natural, conversational experiences that solve problems efficiently.
        </p>
      </div>
      <Container>
        <div className="customer-experience-content">
          <Button 
            variant="primary" 
            className="cta-button"
            onClick={() => navigate('/login')}
          >
            Explore AiTalker
          </Button>
          
          <Row className="flex-column flex-md-row">
            <Col md={6} className="mb-4 mb-md-0">
              <div className="conversation-card-wrapper">
                <Card className="conversation-card">
                  <Card.Body>
                    {!showEmptyCard ? (
                      <div className={`audio-conversation-animation ${fadeOutMessages ? 'fade-out' : ''}`}>
                        {visibleMessages.map((msg, index) => (
                          <div key={`${msg.type}-${index}`} className={`conversation-bubble ${msg.type}`}>
                            {msg.type === 'ai' ? <FaRobot className="icon" /> : <FaUser className="icon" />}
                            <p>{msg.message}</p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <EmptyCardWithTick />
                    )}
                  </Card.Body>
                </Card>
              </div>
            </Col>
            <Col md={6}>
              <RightSideContent />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default CustomerExperience;
