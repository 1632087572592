import React, { useState, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import WaveSurfer from 'wavesurfer.js';
import { FaPlay, FaPause } from 'react-icons/fa';
import './Glossary.css';

// Import audio files
import menaAudio from '../../Audio clips/MENA2.0.wav';
import clip00232Audio from '../../Audio clips/00232.wav';
import clip21230Audio from '../../Audio clips/21230.wav';
import clip41312Audio from '../../Audio clips/41312.wav';

const Glossary = () => {
  const [activeAudio, setActiveAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const wavesurfers = useRef({});

  const audioClips = [
    { name: 'MENA Region', file: menaAudio, description: 'Multilingual AI conversation for the Middle East' },
    { name: 'Customer Support', file: clip00232Audio, description: 'AI assisting with customer support' },
    { name: 'Product Information', file: clip21230Audio, description: 'AI providing detailed product information' },
    { name: 'Troubleshooting', file: clip41312Audio, description: 'AI guiding through troubleshooting steps' }
  ];

  const initializeWaveSurfer = (index) => {
    if (wavesurfers.current[index]) {
      return wavesurfers.current[index];
    }

    const wavesurfer = WaveSurfer.create({
      container: `#waveform-${index}`,
      waveColor: '#D9DCFF',
      progressColor: '#4353FF',
      cursorColor: '#4353FF',
      barWidth: 2,
      barRadius: 3,
      cursorWidth: 1,
      height: 60,
      barGap: 3,
      responsive: true,
      normalize: true,
    });

    wavesurfer.load(audioClips[index].file);
    wavesurfer.on('ready', () => setIsLoading(prev => ({ ...prev, [index]: false })));
    wavesurfer.on('play', () => setIsPlaying(prev => ({ ...prev, [index]: true })));
    wavesurfer.on('pause', () => setIsPlaying(prev => ({ ...prev, [index]: false })));

    wavesurfers.current[index] = wavesurfer;
    return wavesurfer;
  };

  const handlePlay = (index) => {
    if (activeAudio !== null && activeAudio !== index) {
      if (wavesurfers.current[activeAudio]) {
        wavesurfers.current[activeAudio].pause();
      }
    }

    const wavesurfer = initializeWaveSurfer(index);
    
    if (wavesurfer.backend && wavesurfer.backend.ac && wavesurfer.backend.ac.state === 'suspended') {
      wavesurfer.backend.ac.resume().then(() => {
        wavesurfer.playPause();
      });
    } else {
      wavesurfer.playPause();
    }
    
    setActiveAudio(index);
    setIsLoading(prev => ({ ...prev, [index]: false }));
  };

  const cleanupWaveSurfers = () => {
    Object.values(wavesurfers.current).forEach(wavesurfer => {
      if (wavesurfer && typeof wavesurfer.destroy === 'function') {
        wavesurfer.destroy();
      }
    });
    wavesurfers.current = {};
  };

  // Call cleanupWaveSurfers when the component unmounts
  React.useEffect(() => {
    return () => {
      cleanupWaveSurfers();
    };
  }, []);

  return (
    <div className="glossary-section">
      <Container>
        <h2 className="text-center mb-5">Experience AI-Powered Conversations</h2>
        <Row>
          {audioClips.map((clip, index) => (
            <Col md={6} key={index} className="mb-4">
              <div className="audio-card">
                <div className="audio-info">
                  <h3 className="audio-title">{clip.name}</h3>
                  <p className="audio-description">{clip.description}</p>
                </div>
                <div className="audio-controls">
                  <button 
                    className={`play-button ${isPlaying[index] ? 'playing' : ''}`} 
                    onClick={() => handlePlay(index)}
                    disabled={isLoading[index]}
                  >
                    {isLoading[index] ? 'Loading...' : isPlaying[index] ? <FaPause /> : <FaPlay />}
                  </button>
                  <div className="waveform-container">
                    <div id={`waveform-${index}`} className="waveform"></div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Glossary;
