import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import './CookiePreferences.css';

const CookiePreferences = () => {
  const [preferences, setPreferences] = useState({
    necessary: true,
    functional: false,
    analytics: false,
    advertising: false,
  });

  useEffect(() => {
    // Load saved preferences from localStorage
    const savedPreferences = JSON.parse(localStorage.getItem('cookiePreferences'));
    if (savedPreferences) {
      setPreferences(savedPreferences);
    }
  }, []);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setPreferences(prev => ({ ...prev, [name]: checked }));
  };

  const savePreferences = () => {
    localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
    alert('Your cookie preferences have been saved.');
  };

  return (
    <Container className="cookie-preferences">
      <h1>Cookie Preferences</h1>
      <p>Manage your cookie preferences below. Please note that disabling certain types of cookies may impact your experience on our site.</p>
      
      <Form>
        <Form.Group className="mb-3">
          <Form.Check 
            type="switch"
            id="necessary"
            label="Necessary Cookies"
            checked={preferences.necessary}
            onChange={handleChange}
            name="necessary"
            disabled
          />
          <Form.Text className="text-muted">
            These cookies are essential for the website to function properly.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Check 
            type="switch"
            id="functional"
            label="Functional Cookies"
            checked={preferences.functional}
            onChange={handleChange}
            name="functional"
          />
          <Form.Text className="text-muted">
            These cookies enable personalized features and functionality.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Check 
            type="switch"
            id="analytics"
            label="Analytics Cookies"
            checked={preferences.analytics}
            onChange={handleChange}
            name="analytics"
          />
          <Form.Text className="text-muted">
            These cookies help us understand how visitors interact with our website.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Check 
            type="switch"
            id="advertising"
            label="Advertising Cookies"
            checked={preferences.advertising}
            onChange={handleChange}
            name="advertising"
          />
          <Form.Text className="text-muted">
            These cookies are used to deliver relevant ads and marketing campaigns.
          </Form.Text>
        </Form.Group>

        <Button variant="primary" onClick={savePreferences}>
          Save Preferences
        </Button>
      </Form>
    </Container>
  );
};

export default CookiePreferences;
