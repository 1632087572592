import React, { useState } from "react";
import { Form } from "react-bootstrap";

const CountryCodeInput = ({ formData, handleChange, errors }) => {
  const [countryCode, setCountryCode] = useState("+91");

  const countryCodes = [
    { code: "+1", name: "USA" },
    { code: "+91", name: "India" },
    { code: "+44", name: "UK" },
    { code: "+81", name: "Japan" },
    { code: "+61", name: "Australia" },
    { code: "+86", name: "China" },
    { code: "+49", name: "Germany" },
    { code: "+33", name: "France" },
    { code: "+39", name: "Italy" },
    { code: "+7", name: "Russia" },
    { code: "+55", name: "Brazil" },
    { code: "+34", name: "Spain" },
    { code: "+52", name: "Mexico" },
    { code: "+27", name: "South Africa" },
    { code: "+62", name: "Indonesia" },
    { code: "+64", name: "New Zealand" },
    { code: "+82", name: "South Korea" },
    { code: "+46", name: "Sweden" },
    { code: "+31", name: "Netherlands" },
    { code: "+90", name: "Turkey" },
    { code: "+94", name: "Sri Lanka" },
    { code: "+63", name: "Philippines" },
    { code: "+66", name: "Thailand" },
    { code: "+98", name: "Iran" },
    { code: "+972", name: "Israel" },
    { code: "+960", name: "Maldives" },
    { code: "+880", name: "Bangladesh" },
    { code: "+233", name: "Ghana" },
    { code: "+234", name: "Nigeria" },
    { code: "+212", name: "Morocco" },
    { code: "+886", name: "Taiwan" }
  ];

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
    handleChange({ target: { name: "countryCode", value: e.target.value } });
  };

  return (
    <Form.Group controlId="phone" className="w-100 my-2">
      <div className="d-flex">
        <Form.Select
          value={countryCode}
          onChange={handleCountryCodeChange}
          className="me-2 w-25"
        >
          {countryCodes.map((country) => (
            <option key={country.code} value={country.code}>
              {country.name} ({country.code})
            </option>
          ))}
        </Form.Select>
        <Form.Control
          type="tel"
          placeholder="Enter Phone Number"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          isInvalid={!!errors.phone}
        />
      </div>
      <Form.Control.Feedback type="invalid">
        {errors.phone}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default CountryCodeInput;
