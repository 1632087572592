import React from 'react';
import { Link } from 'react-router-dom';
import './BlogCard.css';

export const getMonthsAgo = (date) => {
  const now = new Date();
  const createdDate = new Date(date);
  const monthDiff = (now.getFullYear() - createdDate.getFullYear()) * 12 + now.getMonth() - createdDate.getMonth();
  
  if (monthDiff === 0) return 'This month';
  if (monthDiff === 1) return '1 month ago';
  return `${monthDiff} months ago`;
};

const randomImages = [
  'https://source.unsplash.com/random/800x600?nature',
  'https://source.unsplash.com/random/800x600?forest',
  'https://source.unsplash.com/random/800x600?mountain',
  'https://source.unsplash.com/random/800x600?artificial-intelligence',
  'https://source.unsplash.com/random/800x600?robot',
  'https://source.unsplash.com/random/800x600?technology'
];

const BlogCard = ({ postId, title, date, image }) => {
  const monthsAgo = getMonthsAgo(date);
  const displayImage = image || randomImages[Math.floor(Math.random() * randomImages.length)];

  return (
    <Link to={`/blog/${postId}`} className="blog-card-link">
      <div className="blog-card">
        <div className="blog-card-image-container">
          <img src={displayImage} alt={title} className="blog-card-image" />
        </div>
        <div className="blog-card-content">
          <h3 className="blog-card-title">{title}</h3>
          <div className="blog-card-footer">
            <p className="blog-card-date">{monthsAgo}</p>
            <span className="blog-card-read-more">
              Read More
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
