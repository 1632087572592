import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import './BlogDetailPage.css';

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const BlogDetailPage = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const { postId } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${apiUrl}/api/blog/${postId}`);
        setBlog(response.data);
      } catch (err) {
        setError('Failed to load blog details.');
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [postId, apiUrl]);

  if (loading) {
    return (
      <div className="blog-detail-loading">
        <div className="blog-detail-spinner"></div>
      </div>
    );
  }

  if (error) {
    return <div className="blog-detail-error">{error}</div>;
  }

  if (!blog) {
    return <div className="blog-detail-error">Blog not found!</div>;
  }

  return (
    <div className="blog-detail-container">
      <Link to="/blog" className="blog-detail-back-link">
        <svg xmlns="http://www.w3.org/2000/svg" className="blog-detail-back-icon" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
        </svg>
        Back to Blogs
      </Link>

      {/* Blog Image */}
      {blog.images && blog.images[0] && (
        <img 
          src={blog.images[0]} 
          alt={blog.title} 
          className="blog-detail-image"
        />
      )}

      {/* Blog Title */}
      <h1 className="blog-detail-title">{blog.title}</h1>

      {/* Author and Date */}
      <div className="blog-detail-meta">
        <p className="blog-detail-author">{blog.author}</p>
        <p className="blog-detail-date">Published on {formatDate(blog.createdAt)}</p>
      </div>

      {/* Blog Description (if available) */}
      {blog.description && (
        <div className="blog-detail-description">
          {blog.description}
        </div>
      )}

      {/* Blog Content */}
      <div
        className="blog-detail-content"
        dangerouslySetInnerHTML={{ __html: blog.content }}
      />

      {/* Tags */}
      {blog.tags && blog.tags.length > 0 && (
        <div className="blog-detail-tags">
          <h3>Tags:</h3>
          <div className="blog-detail-tags-list">
            {blog.tags.map((tag, index) => (
              <span key={index} className="blog-detail-tag">
                {tag}
              </span>
            ))}
          </div>
        </div>
      )}

      {/* Back to Top Button */}
      <div className="blog-detail-back-to-top">
        <button
          onClick={scrollToTop}
          className="blog-detail-back-to-top-button"
          aria-label="Back to top"
        >
          Back to Top
        </button>
      </div>
    </div>
  );
};

export default BlogDetailPage;
